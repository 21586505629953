import * as React from 'react';
import { urlService } from '@healthiqeng/core.services.url';
import { decodeBase64Url } from '@healthiqeng/core.util.base64-url';
import { internalAuthService, logger } from '../services';

const RedirectPage: React.FunctionComponent = () => {
  const [errorMessage, setErrorMessage] = React.useState(null);
  React.useEffect(() => {
    const redirect = async () => {
      const state = urlService.getUrl().getParam('state');
      const code = urlService.getUrl().getParam('code');
      const error = urlService.getUrl().getParam('error');
      const errorDescription = urlService.getUrl().getParam('error_description');
      if (error) {
        setErrorMessage(errorDescription || error);
        return;
      }
      await internalAuthService.exchangeAuthorizationCode(code);
      window.location.href = decodeBase64Url(state);
    };
    redirect().catch((err) => logger.crit(err));
  }, []);

  if (errorMessage) {
    return (
      <div>
        <p>There was an error logging you in.</p>
        <p>Please contact IT support and provide the following message:</p>
        <pre>{ errorMessage }</pre>
      </div>
    );
  }

  return (
    <>
      Redirecting...
    </>
  );
};

export default RedirectPage;
